'use strict';

Gri.module({
  name: 'list-beta',
  ieVersion: null,
  $el: $('.list-beta'),
  container: '.list-beta',
  fn: function () {

    const $this = this.$el;
    let heights = [];

    resizeController();
    $(window).on('resize', resizeController);

    function resizeController() {
      let windowW = $(window).width();

      if(windowW > 991) {
        setTimeout(function () {
          $this.find('li').each(function () {
            let thisH = $(this).outerHeight();
            heights.push(thisH);
          });

          let newHeight = Math.max.apply(undefined, heights);
          $this.find('li').outerHeight(newHeight);
        }, 3000);
      }
    }
  }
});
